import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import style from "./ewallet.module.scss";
import { CurrencyDecimalFormat } from "../../helper";
import income from "../../../assets/images/logos/income-w.png";
function Tiles(props) {
  console.log(props);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
    // let ewalletTiles = props.tilesData.map((items) => {
    //   var background;
    //   var img;
    //   switch (items.text) {
    //     case "credited":
    //       background = style.green;
    //       img = "income-w.png";
    //       break;
    //     case "debited":
    //       background = style.red;
    //       img = "Bonus-w.png";
    //       break;
    //     case "ewalletBalance":
    //       img = "E-Wallet-w.png";
    //       background = style.violet;
    //       break;
    //     case "purchaseWallet":
    //       img = "income-w.png";
    //       background = style.pink;
    //       break;
    //     case "commissionEarned":
    //       img = "income-w.png";
    //       background = style.blue;
    //       break;
    //     default:
    //       background = style.blue;
    //       img = "income-w.png";
    //   }
    //   // background = style.blue
    //   return {
    //     amount: items.amount,
    //     text: items.text,
    //     background: background,
    //     img: img,
    //   };
    // });
    // setData(ewalletTiles);
  }, [setData, props.tilesData]);

  return (
    <div className={`${style.ewalletTiles}`}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_Tiles} d-flex`
            : `${style.Tiles} d-flex`
        }`}
      >
        <div className={`${style.leftIcon} `} style={{ background: "green" }}>
          <img src={income} alt="" />
        </div>
        <div
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_tilesData}`
              : `${style.tilesData}`
          }`}
        >
          <h4>{"Potentail Earning"}</h4>
          <span
            className={style.TilesAmount}
            title={(props.tilesData.sum * Currency.value).toFixed(
              Currency.precision
            )}
          >
            {Currency.currentCurr}
            {CurrencyDecimalFormat(
              props.tilesData.sum * Currency.value,
              Currency.precision
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Tiles;
