import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import { Filter } from "./";
import moment from "moment";

function EarningsTable({ ContentData, paginationchange, exportData, loader }) {
  console.log(
    "=================================================",
    ContentData,
    paginationchange,
    exportData,
    loader
  );
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);

  const columns = [
    {
      name: "From user".toUpperCase(),
      selector: (row) => row.fromusername,
      width: "30%",
    },
    {
      name: "Percentage".toUpperCase(),
      selector: (row) => row.percent + "%",
      sortable: true,
    },

    {
      name: "Earnings".toUpperCase(),
      selector: (row) => row.potential_earnings,

      cell: (row) => (
        <span
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_credit} ${style.badge}`
              : `${style.credit} ${style.badge}`
          }`}
        >
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.potential_earnings * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    // {
    //   name: t("Common.tax").toUpperCase(),
    //   selector: (row) => row.tax,
    //   cell: (row) => (
    //     <span className={`${style.balanceAmount} ${style.badge}`}>
    //       {Currency.currentCurr}{" "}
    //       {CurrencyDecimalFormat(row.tax * Currency.value, Currency.precision)}
    //       {/* {Currency.currentCurr} {Number(row.tax).toFixed(2)} */}
    //     </span>
    //   ),
    // },
    // {
    //   name: t("Common.serviceChange").toUpperCase(),
    //   selector: (row) => row.serviceChange,
    //   cell: (row) => (
    //     <span className={`${style.balanceAmount} ${style.badge}`}>
    //       {Currency.currentCurr}{" "}
    //       {CurrencyDecimalFormat(
    //         row.serviceChange * Currency.value,
    //         Currency.precision
    //       )}
    //       {/* {Currency.currentCurr} {Number(row.serviceChange).toFixed(2)}{" "} */}
    //     </span>
    //   ),
    // },
    // {
    //   name: t("Common.amountPayable").toUpperCase(),
    //   selector: (row) => row.amount_payable,
    //   cell: (row) => (
    //     <span className={`${style.balanceAmount} ${style.badge}`}>
    //       {Currency.currentCurr}{" "}
    //       {CurrencyDecimalFormat(
    //         row.amount_payable * Currency.value,
    //         Currency.precision
    //       )}
    //       {/* {Currency.currentCurr} {Number(row.amount_payable).toFixed(2)}{" "} */}
    //     </span>
    //   ),
    // },
    // {
    //   name: t("Common.transactionDate").toUpperCase(),
    //   selector: (row) => row.transaction_date,
    //   sortable: true,
    // },
  ];
  const [state, setState] = useState({ data: [] });
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });

  const { data } = state;

  useEffect(() => {}, [ContentData]);
  const handlePerRowsChange = (perPage, inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");
    paginationchange(inPage, perPage, date.start, date.end, "", "", keys);
  };
  const handlePageChange = (inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");

    paginationchange(
      inPage,
      ContentData.perPage,
      date.start,
      date.end,
      "",
      "",
      keys
    );
  };
  const handleOnSort = ({ selector }, sortDirection) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      date?.start,
      date?.end,

      sortDirection,
      selector,
      keys
    );
  };
  const filterHandlet = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    let keys = [];
    type.map((key, idx) => {
      keys.push(key.value);
    });

    // let keys = type
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");
    loader.EarningsTable.search = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      start,
      end,
      "",
      "",
      keys
    );
  };

  //reset handler
  const resetData = () => {
    SetDate((prev) => ({
      ...prev,
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    }));

    loader.EarningsTable.reset = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      moment().startOf("month").format("MMMM D, YYYY"),
      moment().format("MMMM D, YYYY"),
      "",
      "",
      ""
    );
  };
  const ConvertData = (data) => {
    let newData = data.map((obj) => {
      if (obj.key) {
        return {
          ...obj,
          key: t("ewallet." + obj.key),
        };
      } else {
        return {
          ...obj,
        };
      }
    });
    return newData;
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={ContentData.data}
        highlightOnHover
        progressPending={ContentData.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        //onSort={handleOnSort}
      />
      {/* </DataTableExtensions> */}
    </>
  );
}

export default EarningsTable;
